<template>
  <div>
    <p class="title is-3">Administrare</p>
    <p class="subtitle is-5">Persoane Fizice</p>

    <TabelPersoane tip="pf" />
  </div>
</template>

<script>
import TabelPersoane from "./TabelPersoane";

export default {
  name: "PersoaneFizice",
  data() {
    return {
      searchString: "",
    };
  },
  components: {
    TabelPersoane,
  },
};
</script>

<style scoped>

</style>
